import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import CardSmall from '../components/cardSmall'

export default class DivisionsPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      divisions: this.props.divisions.sort((a, b) => a.node.sequence == b.node.sequence ? 0 : a.node.sequence < b.node.sequence ? -1 : 1),
      companies: [],
      btnActive: this.props.btnId
    }
  }

  componentDidMount() {
    this.setState({
      companies: this.state.divisions,
      btnActive: this.props.btnId 
    })
    this.handleClick(this.props.btnId)
  }

  
  handleClick(value) {
    let companies = []
    this.setState({ btnActive: value })

    if (value === 'all') {
      companies = this.state.divisions
    } else {
      companies = this.state.divisions.filter(divisions => JSON.stringify(divisions.node.sector).match(value))
    }

    companies = companies.sort((a, b) => a.node.sequence == b.node.sequence ? 0 : a.node.sequence < b.node.sequence ? -1 : 1)
    this.setState({ companies: companies })
  }

  render() {
    return (
      <main>
        <section className="bg-light-blue">
        <MDBContainer className="pb-5">
            <MDBRow>
              <MDBCol>
            <MDBBtn 
              className={this.state.btnActive === "all" ? "btn-mdb-select btn-sm active" : "btn-mdb-select btn-sm"}
              value="all"
              onClick={() => this.handleClick("all")}
            >
              All
            </MDBBtn>
            <MDBBtn  
              className={this.state.btnActive === "Enterprise software" ? "btn-mdb-select btn-sm active" : "btn-mdb-select btn-sm"}
              value="Enterprise software"
              onClick={() => this.handleClick("Enterprise software")}
            >
              Enterprise software
            </MDBBtn>
            <MDBBtn 
              className={this.state.btnActive === "IT infrastructure" ? "btn-mdb-select btn-sm active" : "btn-mdb-select btn-sm"}
              value="IT infrastructure"
              onClick={() => this.handleClick("IT infrastructure")}
            >
              IT Infrastructure
            </MDBBtn>
            <MDBBtn 
              className={this.state.btnActive === "Government tech" ? "btn-mdb-select btn-sm active" : "btn-mdb-select btn-sm"}
              value="Government tech"
              onClick={() => this.handleClick("Government tech")}
            >
              Government tech
            </MDBBtn>
            <MDBBtn 
              className={this.state.btnActive === "Real estate/finance" ? "btn-mdb-select btn-sm active" : "btn-mdb-select btn-sm"}
              value="Real estate/finance"
              onClick={() => this.handleClick("Real estate/finance")}
            >
              Finance & Real estate
            </MDBBtn>
            </MDBCol>
            </MDBRow>
          </MDBContainer>

          <MDBContainer>
            <MDBRow>
              {this.state.companies.map(({ node }, index) => (
                <CardSmall
                  key={index}
                  collg="4"
                  colmd="6"
                  avatar={node.avatar.localFile.childImageSharp.fixed}
                  title={node.name}
                  sectordescription={node.sectorDescription}
                  sector={node.sector}
                  linktext={node.name}
                  link={node.url}
                />
              ))}
            </MDBRow>
          </MDBContainer>
        </section>
      </main>
    )
  }
}
