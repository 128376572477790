import React from 'react'
import { graphql } from 'gatsby'
import { responsiveImage } from '../../../utils/helpers.js'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import Hero from '../../../components/heroSubpage'
import DivisionsSection from '../../../components/divisionsSection'

export default ({ data, location }) => {
  const btnId = (location.state != null && location.state.btn != null) ? location.state.btn : "all"
  const hero = responsiveImage(data.heroImage.childImageSharp.fluid.srcSetWebp)

  return (
    <>
      <Layout>
        <div id="sub-page">
          <SEO
            title="Divisions"
            description="UNICOM Global is comprised of over 50 divisions.  UNICOM's technology divisions are outlined below, categorized by industry type/sector." 
            url={data.site.siteMetadata.siteUrl + location.pathname} 
            image={data.site.siteMetadata.siteUrl + hero}

          />

          <Hero
            class="intro-65"
            image={hero}
            title="UNICOM Global Divisions"
            alt="office blocks against blue sky"
            subtitle="UNICOM Global consists of more than 50 corporate entities encompassing a wide range of businesses."
            type="root"
          />
        </div>

        <DivisionsSection divisions={data.allContentfulDivision.edges} btnId={btnId} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allContentfulDivision(
      sort: { fields: [sequence], order: ASC }
      filter: { sector: { ne: "None" } }
    ) {
      edges {
        node {
          avatar {
            localFile {
              childImageSharp {
                fixed(width: 50, height: 50, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          sector: tag
          sectorDescription
          name
          sequence
          url
        }
      }
    }
    heroImage: file(name: { eq: "divisions-banner" }) {
      childImageSharp {
        fluid(maxWidth: 1980, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
